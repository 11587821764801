$color-info: #0dcaf0 !default;
$color-success: #198754 !default;
$color-warning: #ffc107 !default;
$color-danger: #dc3545 !default;

.active > .MuiListItem-button {
    background-color: #b8b8b8;
    &:hover {
        background-color: #b8b8b8;
    }
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik'), url(../assets/Rubik-Regular.ttf) format('truetype');}

.d-flex {
    display: flex !important;
}
.loader {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1550;
    cursor: wait;
    top: 40%;
    left: 50%;
}
.j-center {
    justify-content: center !important;
}

.text-success {
    color: $color-success;
}
.text-danger {
    color: $color-danger;
}

.bg-danger {
    background-color: #dc3545;
}
.bg-warning {
    background-color: $color-warning;
}
.bg-success {
    background-color: $color-success;
}

.pointer {
    cursor: pointer !important;
}
.MuiAutocomplete-option {
    display: inherit !important;
}

.number-input > .MuiOutlinedInput-root {
        border-radius: 0 !important; 
        
}